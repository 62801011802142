@import "../../styles/simples/_variables.scss";
@import "../../styles/simples/_functions.scss";

.profile-view-settings-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    @include breakpoint-max(sm) {
        justify-content: center;
    }
}
