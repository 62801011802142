@import "../../styles/simples/_variables";
@import "../../styles/simples/_functions";

.dots-button {
    cursor: pointer;
    border: 0;
    outline: 0;
    margin: 0;
    padding: 5px;
    svg circle {
        fill: #000;
    }

    &:hover {
        border: 0;
        outline: 0;
        svg circle {
            fill: $primary;
        }
    }

    svg {
        @include enforce-size(width, 25px);
        @include enforce-size(height, 6px);

        cursor: inherit;
    }
}
