@import "../../styles/simples/_variables.scss";
@import "../../styles/simples/_functions.scss";

.banner-events {
    background-position: "center top";
    @include enforce-size(height, 662px);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 36px;
}

.white-card {
    background-color: white;
    border-radius: 4pt;
    color: $text;
    padding: 25px 20px;
    width: 550px;
    max-width: 95%;
    text-align: center;
}
