.profile-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-image-element {
    flex: 1;

    width: 75px;
    height: 75px;
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
