@import "../../styles/simples/_variables";
@import "../../styles/simples/_functions";

.admin-user {
    padding: 20px;
}

.admin-user-fields {
    width: 800px;
    max-width: 90%;
    margin: 0 auto;
}

.admin-field-row {
    display: flex;
    margin: 5px;
    &> * {
        flex: 1;
        display: flex;
        align-items: center;
    }

    label {
        @include enforce-size(width, 150px);
    }

    .select-dropdown-wrapper {
        margin-right: 30px;
        select {
            @include enforce-size(width, 100%);
        }
    }

    img {
        flex: 0;
        height: auto;
        max-height: 60px;
        width: auto;
        max-width: 200px;
    }

    &.image {
        min-height: 60px;
        align-items: center;
    }
}
