@import "../../../styles/simples/_variables.scss";
@import "../../../styles/simples/_functions.scss";

.banner {
    min-height: 380px;
    background-color: $primary;
    background-position: center;
    background-size: cover;
    color: $white;

    .banner-title-section {
        @include enforce-size(height, 150px);
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        @include breakpoint-max(sm) {
            height: auto;
            max-height: unset;
        }
    }

    .banner-title {
        font-weight: bold;
        font-size: 46px;
        text-align: center;
        span.banner-subtitle {
            font-weight: normal;
        }
    }

    .banner-icons-section {
        max-width: 1080px;
        margin: 0 auto;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px;
    }

    .banner-action {
        font-size: 28.19px;
        font-weight: 600;
        color: black;
        display: inline-block;

        svg {
            margin-left: 10px;
            margin-top: 7px;
            @include enforce-size(width, 25px);
        }
    }
}

ul.banner-categories-list {
    flex: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    list-style-type: none;

    a {
      margin: 3px;
    }

    li {
        flex: 1;
        @include enforce-size(height, 146px);
        @include enforce-size(width, 88px);
        margin: 5px 10px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        .banner-icon-item {
            @include enforce-size(width, 88px);
            @include enforce-size(height, 88px);

            border-radius: 50%;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 55%;
                height: auto;
                max-height: 65%;
            }
        }

        p {
            text-align: center;
            font-weight: bold;
        }
    }
}
