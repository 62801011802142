@import "./simples/reset.scss";
@import "./simples/_variables.scss";
@import "./simples/_functions.scss";


.#{$prefix}btn {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 5px;
    background-color: map-get($color-map, "primary");
    border: 1px solid darken(map-get($color-map, "primary"), 10%);
    max-width: 100%;
    border-radius: 4pt;
    padding: 5px 10px;
    text-decoration: none;
    &:hover {
        background-color: darken(map-get($color-map, "primary"), 10%);
        border-color: darken(map-get($color-map, "primary"), 30%);
        text-decoration: none;
    }
    @each $size, $size-map in $sizes-map {
        &.#{$prefix}btn-len-#{$size} {
            width: calc(#{map-get($size-map, "font-size")} * 10);
            max-width: calc(#{map-get($size-map, "font-size")} * 10);
            min-width: calc(#{map-get($size-map, "font-size")} * 10);
        }
    }
    &:focus {
        outline: 0;
        box-shadow: 0 0 2px 1px transparentize($black, 0.6);
    }

    &:disabled {
        background-color: $gray;
        color: white;
        border-color: $gray;
        cursor: not-allowed;
        pointer-events: none;   
    }
}

.#{$prefix}t-left {
    text-align: left;
}

.#{$prefix}t-center {
    text-align: center;
}

.#{$prefix}t-right {
    text-align: right;
}

.#{$prefix}t-justify {
    text-align: justify;
}

.#{$prefix}t-capitalize {
    text-transform: capitalize;
}

.#{$prefix}t-upper {
    text-transform: uppercase;
}

.#{$prefix}t-down {
    text-transform: lowercase;
}

.#{$prefix}t-smallcaps {
    font-variant: small-caps;
}

.#{$prefix}thin-italic {
    font-style: italic;
    font-weight: lighter;
}
    

.#{$prefix}link {
    color: map-get($color-map, "primary");
    cursor: pointer;
    &:hover {
        text-decoration: none;
        color: darken(map-get($color-map, "primary"), 10%);
    }
}



@each $name, $color in $color-map {

    .#{$prefix}btn-#{$name}, {
        background-color: $color;
        border-color: darken($color, 10%);
        &:hover {
            cursor: pointer;
            background-color: darken($color, 10%);
            border-color: darken($color, 30%);
        }
    }
    .#{$prefix}color-#{$name} {
        color: $color;
        &.#{$prefix}btn-clear:hover {
            color: darken($color, 30%);
        }
    }
    svg.#{$prefix}color-#{$name} {
        fill: $color;
    }
    a.#{$prefix}color-#{$name}:hover {
        color: darken($color, 30%);
    }
    .#{$prefix}bg-#{$name} {
        background-color: $color;
    }

    .#{$prefix}clickable-#{$name} {
        border: 1px solid darken($color, 10%);
        background-color: $color;
        &:hover {
            border-color: 1px solid darken($color, 30%);
            background-color: darken($color, 10%);
        }
    }

    .#{$prefix}inherit-color-#{$name} {
        .#{$prefix}inherit-color {
            color: $color;
        }
    }

    .#{$prefix}link-#{$name} {
        color: $color;
        cursor: pointer;
        &:hover {
            text-decoration: none;
            color: darken($color, 10%);
        }
    }

    .#{$prefix}link-#{$name}-inverse {
        color: $color;
        cursor: pointer;
        &:hover {
            text-decoration: none;
            color: lighten($color, 20%);
        }
    }
}

.#{$prefix}no-dec {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

.#{$prefix}clickable {
    &:hover {
        cursor: pointer;
    }
}

@each $size, $size-map in $sizes-map {
    .#{$prefix}btn-size-#{$size} {
        font-size: map-get($size-map, "font-size");
        line-height: map-get($size-map, "line-height");
        height: map-get($size-map, "btn-height");
        min-height: map-get($size-map, "btn-height");
    }

    @each $breakpoint, $br-size in $screen-breakpoints {
        @include breakpoint-max($breakpoint) {
            .#{$prefix}#{$breakpoint}\:btn-size-#{$size} {
                font-size: map-get($size-map, "font-size");
                line-height: map-get($size-map, "line-height");
                height: map-get($size-map, "btn-height");
                min-height: map-get($size-map, "btn-height");
            }
        }
    }
}


@each $size in 100, 200, 300, 400, 500, 600, 700, 800 {
    .#{$prefix}font-size-#{$size} {
        font-weight: $size;
    }

    @each $breakpoint, $br-size in $screen-breakpoints {
        @include breakpoint-max($breakpoint) {
            .#{$prefix}#{$breakpoint}\:font-size-#{$size} {
                font-weight: $size;
            }
        }
    }
}


@for $i from 0 through 10 {

    @for $i2 from 0 through 9 {
        .#{$prefix}fx-#{$i}-#{$i2}, .#{$prefix}children-fx-#{$i}-#{$i2} > * {
            flex: #{$i + ($i2 * 0.1)};
        }
        .#{$prefix}fx-#{$i}#{$i2}, .#{$prefix}children-fx-#{$i}#{$i2} > * {
            @include enforce-size(width, unquote("#{$i}#{$i2}%"));
            flex: 1;
        }

        @each $breakpoint, $br-size in $screen-breakpoints {
            @include breakpoint-max($breakpoint) {
                .#{$prefix}#{$breakpoint}\:fx-#{$i}-#{$i2}, .#{$prefix}#{$breakpoint}\:children-fx-#{$i}-#{$i2} > * {
                    flex: #{$i + ($i2 * 0.1)};
                }
                .#{$prefix}#{$breakpoint}\:fx-#{$i}#{$i2}, .#{$prefix}#{$breakpoint}\:children-fx-#{$i}#{$i2} > * {
                    @include enforce-size(width, unquote("#{$i}#{$i2}%"));
                    flex: 1;
                }
            }
        }
    }

    .#{$prefix}fx-100, .#{$prefix}children-fx-100 > * {
        @include enforce-size(width, 100%);
    }

    @each $breakpoint, $br-size in $screen-breakpoints {
        @include breakpoint-max($breakpoint) {
            .#{$prefix}#{$breakpoint}\:fx-100, .#{$prefix}#{$breakpoint}\:children-fx-100 > * {
                @include enforce-size(width, 100%);
            }
        }
    }

    $val: $i;
    
    @each $cond in (false, true) {
        @if $cond {
            $val: $i / 10;
            $i: "0#{$i}";
        }
        
        @each $side, $short in $sides {
            .#{$prefix}m#{$short}-#{$i}, .#{$prefix}children-m#{$short}-#{$i} > * {
                margin-#{$side}: #{$val * 10}px;
            }
            .#{$prefix}p#{$short}-#{$i}, .#{$prefix}children-p#{$short}-#{$i} > * {
                padding-#{$side}: #{$val * 10}px;
            }

            @each $breakpoint, $br-size in $screen-breakpoints {
                @include breakpoint-max($breakpoint) {
                    .#{$prefix}#{$breakpoint}\:m#{$short}-#{$i}, .#{$prefix}#{$breakpoint}\:children-m#{$short}-#{$i} > * {
                        margin-#{$side}: #{$val * 10}px !important;
                    }
                    .#{$prefix}#{$breakpoint}\:p#{$short}-#{$i}, .#{$prefix}#{$breakpoint}\:children-p#{$short}-#{$i} > * {
                        padding-#{$side}: #{$val * 10}px !important;
                    }
                }
            }
        }
        
        .#{$prefix}mx-#{$i}, .#{$prefix}children-mx-#{$i} > * {
            margin-left: #{$val * 10}px;
            margin-right: #{$val * 10}px;
        }
        
        .#{$prefix}my-#{$i}, .#{$prefix}children-my-#{$i} > * {
            margin-top: #{$val * 10}px;
            margin-bottom: #{$val * 10}px;
        }
        
        .#{$prefix}m-#{$i}, .#{$prefix}children-m-#{$i} > * {
            margin: #{$val * 10}px;
        }
        
        .#{$prefix}px-#{$i}, .#{$prefix}children-px-#{$i} > * {
            padding-left: #{$val * 10}px;
            padding-right: #{$val * 10}px;
        }
        
        .#{$prefix}py-#{$i}, .#{$prefix}children-py-#{$i} > * {
            padding-top: #{$val * 10}px;
            padding-bottom: #{$val * 10}px;
        }
        
        .#{$prefix}p-#{$i}, .#{$prefix}children-p-#{$i} > * {
            padding: #{$val * 10}px;
        }

        @each $breakpoint, $br-size in $screen-breakpoints {
            @include breakpoint-max($breakpoint) {
                .#{$prefix}#{$breakpoint}\:mx-#{$i}, .#{$prefix}#{$breakpoint}\:children-mx-#{$i} > * {
                    margin-left: #{$val * 10}px !important;
                    margin-right: #{$val * 10}px !important;
                }
                
                .#{$prefix}#{$breakpoint}\:my-#{$i}, .#{$prefix}#{$breakpoint}\:children-my-#{$i} > * {
                    margin-top: #{$val * 10}px !important;
                    margin-bottom: #{$val * 10}px !important;
                }
                
                .#{$prefix}#{$breakpoint}\:m-#{$i}, .#{$prefix}#{$breakpoint}\:children-m-#{$i} > * {
                    margin: #{$val * 10}px !important;
                }
                
                .#{$prefix}#{$breakpoint}\:px-#{$i}, .#{$prefix}#{$breakpoint}\:children-px-#{$i} > * {
                    padding-left: #{$val * 10}px !important;
                    padding-right: #{$val * 10}px !important;
                }
                
                .#{$prefix}#{$breakpoint}\:py-#{$i}, .#{$prefix}#{$breakpoint}\:children-py-#{$i} > * {
                    padding-top: #{$val * 10}px !important;
                    padding-bottom: #{$val * 10}px !important;
                }
                
                .#{$prefix}#{$breakpoint}\:p-#{$i}, .#{$prefix}#{$breakpoint}\:children-p-#{$i} > * {
                    padding: #{$val * 10}px !important;
                }
            }
        }
    }
}


.#{$prefix}mx-auto, .#{$prefix}children-mx-auto > * {
    margin-left: auto !important;
    margin-right: auto !important;
}

.#{$prefix}ml-auto, .#{$prefix}children-ml-auto > * {
    margin-left: auto !important;
}

.#{$prefix}mr-auto, .#{$prefix}children-mr-auto > * {
    margin-right: auto !important;
}

.#{$prefix}fx-row, .#{$prefix}children-fx-row > * {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
}

.#{$prefix}fx-nowrap, .#{$prefix}children-fx-nowrap > * {
    flex-wrap: nowrap;
}

.#{$prefix}fx-column, .#{$prefix}children-fx-column > * {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
}


.#{$prefix}justify-content-around, .#{$prefix}children-justify-content-around > * {
    justify-content: space-around;
}

.#{$prefix}justify-content-between, .#{$prefix}children-justify-content-between > * {
    justify-content: space-between;
}

.#{$prefix}justify-content-center, .#{$prefix}children-justify-content-center > * {
    justify-content: center;
}

.#{$prefix}justify-content-start, .#{$prefix}children-justify-content-start > * {
    justify-content: flex-start;
}

.#{$prefix}justify-content-end, .#{$prefix}children-justify-content-end > * {
    justify-content: flex-end;
}

.#{$prefix}align-items-center, .#{$prefix}children-align-items-center > * {
    align-items: center;
}

.#{$prefix}align-items-around, .#{$prefix}children-align-items-around > * {
    align-items: space-around;
}

.#{$prefix}align-items-between, .#{$prefix}children-align-items-between > * {
    align-items: space-between;
}

.#{$prefix}align-items-start, .#{$prefix}children-align-items-start > * {
    align-items: flex-start;
}

.#{$prefix}align-items-end, .#{$prefix}children-align-items-end > * {
    align-items: flex-end;
}

.#{$prefix}underline {
    text-decoration: underline;
}

@each $side in $alignments {
    .#{$prefix}text-#{$side} {
        text-align: $side;
    }
    @each $breakpoint, $br-size in $screen-breakpoints {
        @include breakpoint-max($breakpoint) {
            .#{$prefix}#{$breakpoint}\:text-#{$side} {
                text-align: $side;
            }
        }
    }
}


@each $breakpoint, $br-size in $screen-breakpoints {
    @include breakpoint-max($breakpoint) {
        .#{$prefix}#{$breakpoint}\:mx-auto, .#{$prefix}#{$breakpoint}\:children-mx-auto > * {
            margin-left: auto !important;
            margin-right: auto !important;
        }

        .#{$prefix}#{$breakpoint}\:ml-auto, .#{$prefix}#{$breakpoint}\:children-ml-auto > * {
            margin-left: auto !important;
        }

        .#{$prefix}#{$breakpoint}\:mr-auto, .#{$prefix}#{$breakpoint}\:children-mr-auto > * {
            margin-right: auto !important;
        }

        .#{$prefix}#{$breakpoint}\:fx-row, .#{$prefix}#{$breakpoint}\:children-fx-row > * {
            display: flex;
            flex-flow: row wrap !important;
            width: 100% !important;
        }

        .#{$prefix}#{$breakpoint}\:fx-column, .#{$prefix}#{$breakpoint}\:children-fx-column > * {
            display: flex;
            flex-flow: column nowrap !important;
            width: 100% !important;
        }


        .#{$prefix}#{$breakpoint}\:justify-content-around, .#{$prefix}#{$breakpoint}\:children-justify-content-around > * {
            justify-content: space-around;
        }

        .#{$prefix}#{$breakpoint}\:justify-content-between, .#{$prefix}#{$breakpoint}\:children-justify-content-between > * {
            justify-content: space-between;
        }

        .#{$prefix}#{$breakpoint}\:justify-content-center, .#{$prefix}#{$breakpoint}\:children-justify-content-center > * {
            justify-content: center;
        }

        .#{$prefix}#{$breakpoint}\:justify-content-start, .#{$prefix}#{$breakpoint}\:children-justify-content-start > * {
            justify-content: flex-start;
        }

        .#{$prefix}#{$breakpoint}\:justify-content-end, .#{$prefix}#{$breakpoint}\:children-justify-content-end > * {
            justify-content: flex-end;
        }

        .#{$prefix}#{$breakpoint}\:align-items-center, .#{$prefix}#{$breakpoint}\:children-align-items-center > * {
            align-items: center;
        }

        .#{$prefix}#{$breakpoint}\:align-items-around, .#{$prefix}#{$breakpoint}\:children-align-items-around > * {
            align-items: space-around;
        }

        .#{$prefix}#{$breakpoint}\:align-items-between, .#{$prefix}#{$breakpoint}\:children-align-items-between > * {
            align-items: space-between;
        }

        .#{$prefix}#{$breakpoint}\:align-items-start, .#{$prefix}#{$breakpoint}\:children-align-items-start > * {
            align-items: flex-start;
        }

        .#{$prefix}#{$breakpoint}\:align-items-end, .#{$prefix}#{$breakpoint}\:children-align-items-end > * {
            align-items: flex-end;
        }

        .#{$prefix}#{$breakpoint}\:underline {
            text-decoration: underline;
        }
    }
}


strong {
    font-weight: bold;
}
