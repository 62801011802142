@import "../../../styles/simples/_variables.scss";
@import "../../../styles/simples/_functions.scss";

@mixin mobile_card($helpers) {
    $mobile-width: 175px;
    $mobile-height: 325px;
    $mobile-image-height: 136px;     
    $margin-bottom: 12px;        

    @if $helpers {
        .helper-card-dimensions {
            @include enforce-size(width, $mobile-width);
            @include enforce-size(height, $mobile-height);
        }
    }
    
    .item-card, .small-card {
        @include enforce-size(width, $mobile-width);
        @include enforce-size(height, $mobile-height);
        @include enforce-size(margin-bottom, $margin-bottom);

        .item-card-seller {
            @include enforce-size(height, 40px);

            padding: 5px;

            .item-card-seller-name {
                font-size: 14px;
            }

            .item-card-seller-icon {
                @include enforce-circle(15px);
            }
        }

        .item-card-image {
            @include enforce-size(height, $mobile-image-height);
            img {
                @include enforce-size(height, $mobile-image-height);
            }
        }

        .item-card-info {
            @include enforce-size(height, 107px);
            padding: 5px 10px;

            .item-card-info-title {
                font-size: 16px;
            }

            .item-card-info-price {
                font-size: 20px;
                
                .currency-sym {
                    font-size: 16px;
                    font-family: "Arial";
                }
            }
        }

        .item-card-contact-footer {
            padding: 15px 10px 5px;
            font-size: 14px;
        }
    }    
}
