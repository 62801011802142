@import "../../../styles/simples/_variables.scss";
@import "../../../styles/simples/_functions.scss";
@import "./item_card_mixin.scss";


$card-width: 238px;
$card-height: 383px;
$card-image-height: 185px;     
$card-margin-bottom: 12px;

@mixin card-dimensions {
    @include enforce-size(width, $card-width);
    @include enforce-size(height, $card-height);
}

.helper-card-dimensions {
    @include card_dimensions;
}

.item-feed {
    .item-card {
        &:first-child {
            margin-left: auto;
        }
        &:last-child {
            margin-right: auto;
        }

        @include breakpoint-max(sm) {
            &:first-child {
                margin-left: 4px;
            }
            &:last-child {
                margin-right: 4px;
            }
        }
    }
}

.item-card {
    @include card_dimensions;

    font-family: "Catamaran";
    font-weight: bold;
    flex: 1;

    background-color: white;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 10px;

    .item-card-seller {
        @include enforce-size(height, 32.5px);
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        padding: 5px;

        .item-card-seller-name {
            font-size: 14px;
            margin-left: 10px;
        }

        .item-card-seller-icon, .profile-image-element {
            @include enforce-circle(15px);
        }
    }

    .item-card-image {
        display: flex;
        justify-content: center;
        align-items: center;
        @include enforce-size(height, $card-image-height);
        img {
            max-width: 100%;
            max-height: $card-image-height;
        }
    }

    .item-card-info {
        @include enforce-size(height, 110px);

        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        padding: 5px 10px;

        .item-card-info-title {
            flex: 1;
            font-size: 18px;
        }

        .item-card-info-price {
            font-size: 24px;

            .currency-sym {
                font-size: 20px;
                font-family: "Arial";
            }
        }
    }

    .item-card-contact-footer {
        padding: 15px 10px 5px;
        color: $primary;
        position: relative;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
        }
        svg {
            margin-left: 10px;
            @include enforce-size(width, 20px);
        }
        &:before {
            position: absolute;
            margin-left: 5%;
            content: "";
            top: 0;
            left: 0;
            width: 90%;
            height: 10px;
            background-image: url("#{$cloudfront-public}/main/svg-zigzag-break.svg");
            background-repeat: repeat-x;
            background-position: center;
        }
    }
}


//@include mobile_card(true);


@include breakpoint-max(sm) {
    @include mobile_card(true);
}
