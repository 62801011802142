@import "../../styles/simples/_variables.scss";
@import "../../styles/simples/_functions.scss";

.popup-window {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    .popup-content {
        // width: 720px;
        // max-width: 90%;
        background-color: $background;
        padding: 20px;
        border-radius: 4pt;

        position: relative;
    
        button.input-clear {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
}
