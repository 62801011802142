@import "../../components/global/navigation/_nav_variables.scss";

.default-layout {
    padding-top: $nav-variables-height-normal !important;
}

.main-container {
    min-height: calc(100vh - #{$nav-variables-height-normal});
}

.legal-content {
  padding: 20px;
  max-width: 768px;
  margin: 10px auto;

  h1 {
    font-size: 30px;
    font-weight: 500;
  }

  h3 {
    font-family: "Zilla Slab";
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  ol {
    margin-bottom: 10px;

    li {
      margin-left: 5px;
      margin-bottom: 10px;
    }
  }

  strong {
    font-weight: 500;
  }
}
