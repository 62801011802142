@import "../../components/sections/feeds/item_card_mixin.scss";

.profile-view-favorites-container {
    padding: 20px;
    display: flex;
    flex-flow: row wrap;
    .item-card {
        margin: 10px;
    }

    @include mobile_card(false);
}
