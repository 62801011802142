@import "../../../styles/simples/_variables.scss";
@import "../../../styles/simples/_functions.scss";

.profile-messages-container, .navigation-session-messages-dropdown {

    .message-row {
        position: relative;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;

        @include enforce-size(height, 140px);

        
        
        margin: 5px auto;
        background-color: white;
        padding: 10px;

        &.unread {
            background-color: lighten($primary, 30%);
        }

        .message-row-first-half {
            position: relative;
            flex: 1;
            display: flex;
            flex-flow: row wrap;

            .message-product-image-container {
                flex: 1;
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;
                align-items: center;
                padding: 5px 5px 0;
                
                .message-product-image {
                    flex: 1;
                    width: 90%;
                    background-size: contain;
                    background-position: center top;
                    background-repeat: no-repeat;
                }
            }

            .message-seller-image {
                flex: 0.6;
                height: 50%;
                align-self: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .message-seller-name {
                flex: 1;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 20px;

                font-weight: 600;
            }
        }

        .message-row-last-half {
            flex: 1;
            display: flex;
            flex-flow: row nowrap;
            padding: 10px;

            .message-reply-date {
                font-size: 16px;
                color: $gray;
            }

            .message-reply {
                margin-top: 5px;
                font-size: 18px;

                span {
                    font-weight: bold;
                    text-transform: capitalize;
                }

                span.from-you {
                    color: $primary;
                }

                span.from-seller {
                    color: $info;
                }

                span.from-buyer {
                    color: $success;
                }
            }

            button {
                transition: transform 0.2s;
            }
        }

        &:hover {
            background-color: transparentize(white, 0.3);
            .message-row-last-half button {
                transform: translateX(10px);
                svg {
                    fill: $primary;
                }
            }
        }
    }

    @include breakpoint-max(md) {
        
        .message-row {
            flex-flow: column nowrap;
            @include enforce-size(height, 280px);
            
            .message-row-first-half {
            }
            
            .message-row-last-half {
                margin-top: 10px;
            }
        }
    }

    @include breakpoint-max(sm) {

        border-top: 1px solid $gray;
        margin-top: 20px;
        
        .message-row {

            flex-flow: row nowrap;
            @include enforce-size(height, 180px);

            border-top: 2px solid $light-gray;
            
            .message-row-first-half {
                @include enforce-size(width, 100px);

                .message-seller-image {
                    position: absolute;
                    right: -5px;
                    top: -7.5px;
                    width: 30px;
                    height: 30px;
                }

                .message-seller-name {
                    display: none;
                }
                
            }
            
            .message-row-last-half {
                margin-top: 10px;
            }

            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.profile-self-content {
    
    @include breakpoint-max(sm) {
        background-color: white;

        .profile-view-title-row {
            display: flex;
            flex-flow: column nowrap;
            span {
                display: block;
            }
        }
    }
}

.message-product-status {
    flex: 1;
    @include enforce-size(height, 14px);

    margin-top: 5px;
    font-size: 14px;
    text-transform: capitalize;

}


.status-available {
    color: $success;
}

.status-unavailable {
    color: $error;
}
