@import "../../../../styles/simples/_variables.scss";
@import "../../../../styles/simples/_functions.scss";

.listing-row, .listing-row-header {
    display: flex;
    flex-flow: row nowrap;
    border-top: 2px solid $light-gray;
    padding: 10px 0; 

    .listing-row-image, .listing-row-title, .listing-row-inquiries, .listing-row-status, .listing-row-action {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }

    .listing-row-image {
        @include enforce-square(100px);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .listing-row-title {
        flex-flow: column nowrap;
        align-items: flex-start;
        font-size: 26px;
        font-weight: 600;
        .listing-row-price {
            font-size: 22px;
            font-weight: normal;
        }
    }
    
    .listing-row-inquiries {
        font-weight: 600;
        font-size: 37.7px;
    }
}

.listing-row-draft, .listing-row-paused, .listing-row-live {
    border: 2px solid transparent;
    padding: 3px 10px;
    border-radius: 4pt;
    font-weight: bold;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    svg {
        @include enforce-circle(5px);
        margin-right: 10px;
    }
}

.listing-row-draft {
    border-color: $light-gray;
    color: $light-gray;
    svg {
        fill: $light-gray;
    }
}

.listing-row-live {
    border-color: $success;
    color: $success;
    svg {
        fill: $success;
    }
}

.listing-row-paused {
    border-color: $warning;
    color: $warning;
    svg {
        fill: $warning;
    }
}

.listing-row-header {
    @include enforce-size(height, 30px);
    border: 0;
    margin-bottom: 10px;

    .listing-row-image, .listing-row-title, .listing-row-inquiries, .listing-row-status, .listing-row-action {
        font-weight: normal;
        font-size: 16px;
        p {
            font-weight: inherit;
            font-size: inherit;
        }
    }
}

@include breakpoint-max(sm) {
    .listing-row {
        flex-flow: row wrap;

        .listing-row-image {
            @include enforce-square(60px);
        }
    }
}
    
