@import "../../../../styles/simples/_variables";
@import "../../../../styles/simples/_functions";


.listing__option__section {
    padding: 0 20px 20px;
    border-radius: 0;
    border: 1px solid $light-gray;
    margin-top: 10px;
}

.listing__option__headline {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    border-bottom: 1px solid $light-gray;
    p {
        flex: 1;
    }

    .listing__option__headline__actions {
        position: relative;
        flex: 1;
        @include enforce-size(width, 35px);

        display: flex;
        justify-content: center;
        align-content: center;

        .listing__option__dropdown {
            position: absolute;
            top: calc(100% + 1px);
            right: 0;
            width: 150px;
            background-color: white;
            padding: 20px 10px 20px;
            z-index: 1;
            border: 1px solid $light-gray;
            border-top: 0;
            
            button {
                width: 100%;
                font-weight: bold;
                font-size: 14px;
                margin: 0 0 0 auto;
                text-align: right;
                display: flex;
                @include enforce-size(height, 30px);
                justify-content: flex-end;
                align-items: center;
                
                &:active, &:focus {
                    border: 0;
                    outline: 0;
                    box-shadow: unset;
                }
                
                &:disabled {
                    color: $light-gray;
                }
                
                &:hover {
                    color: $primary;
                    
                    &:disabled {
                        color: $light-gray;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}

input.option-section-title {
    border: 0;
    outline: 0;

    &:active, &:focus {
        border: 0;
        outline: 0;
    }

    font-size: 42px;
    background-color: transparent;
}

.listing__option_section__options {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;

    button {
        flex: 1;
        @include enforce-size(width, 100px);
    }
}

.listing__option__section__option {
    flex: 1;
    position: relative;

    @include enforce-size(width, 320px);
    @include enforce-size(height, 130px);

    border: 1px solid $light-gray;
    border-radius: 4pt;

    margin: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-size: 34px;
    font-style: italic;

    input {
        width: inherit;
        height: inherit;
        text-align: center;
    }

    .input-clear {
        @include enforce-circle(28px);
        position: absolute;
        right: 5px;
        top: 5px;
    }
}
