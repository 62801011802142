@import "../simples/_variables.scss";
@import "../simples/_functions.scss";

input {
    @include base_input_style();
    width: 100%;

    &[type="checkbox"] {
        width: auto;
        width: 30px;
        height: 30px;
    }
}

.input-wrapper {
    position: relative;
}

.input-errors {
    position: absolute;
    top: 100%;
    left: 0px;
    width: 100%;
    padding: 5px;
    text-align: center;
    border-radius: 4pt;
    border: 2px solid $primary;
    background-color: white;
}

.label-title {
    font-weight: bold;
    font-size: 28px;
}


.select-dropdown-wrapper {
    @include enforce-size(width, 200px);

    position: relative;
    color: $primary;
    display: flex;

    font-size: 16px;
    font-weight: lighter;
    &::after {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 7px;
        top: 5px;
        height: 30px;
        min-height: 30px;
        max-height: 30px;
        line-height: 20px;
        width: 18px;
        min-width: 18px;
        content: "\2228";
        z-index: 20;
        -webkit-transform:scale(1.8,1);
        -moz-transform:scale(1.8,1);
        -ms-transform:scale(1.8,1);
        -o-transform:scale(1.8,1);
        transform:scale(1.8,1);
        pointer-events: none;
    }
}

.select-dropdown {
    position: relative;
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #444;
    line-height: 1.3;
    padding: 10px 10px 10px 30px;
    flex: 1;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border-radius: 2pt;
    border: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: $background;
    outline: none;

    text-transform: capitalize;
}
.select-dropdown::-ms-expand {
    display: none;
}
.select-dropdown:hover {
    box-shadow: inset 0 0 40px 2px rgba(0,0,0,.2);
}

.select-dropdown option {
    font-weight: 400;
}

button.input-clear {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    color: $primary;
    font-weight: 800;
    font-size: 26px;
    line-height: 26px;
    cursor: pointer;
    padding-bottom: 1px;

    @include enforce-circle(28px);

    &::before {

        @include enforce-circle(28px);

        content: "";
        background-image: url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTY1LjQ0IDE0My43MSI+PGRlZnM+PHN0eWxlPi5pbnB1dC1jbGVhci1wcmltYXJ5e2ZpbGw6dXJsKCNyYWRpYWwtZ3JhZGllbnQpO308L3N0eWxlPjxyYWRpYWxHcmFkaWVudCBpZD0icmFkaWFsLWdyYWRpZW50IiBjeD0iOTMuNzciIGN5PSI3MS44NSIgcj0iODMuNTMiIGdyYWRpZW50VHJhbnNmb3JtPSJzY2FsZSgwLjg4IDEpIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHN0b3Agb2Zmc2V0PSIwLjI0IiBzdG9wLWNvbG9yPSIjZmY4NjgzIi8+PHN0b3Agb2Zmc2V0PSIwLjg3IiBzdG9wLWNvbG9yPSIjZjQ5ZTgzIi8+PC9yYWRpYWxHcmFkaWVudD48L2RlZnM+PGc+PGc+PHBvbHlnb24gY2xhc3M9ImlucHV0LWNsZWFyLXByaW1hcnkiIHBvaW50cz0iMTY1LjQ0IDAuMzkgMTI3LjE3IDAuMzkgODIuODkgNTAuNTggMzguMjcgMCAwIDAgNjMuNzYgNzIuMjcgNjMuNTkgNzIuNDYgNjMuNzkgNzIuNjkgMS40OCAxNDMuMzIgMzkuMTQgMTQzLjE3IDgyLjU1IDkzLjk2IDEyNi4zIDE0My41NSAxNjMuOTYgMTQzLjcxIDEwMS40OCA3Mi44OCAxNjUuNDQgMC4zOSIvPjwvZz48L2c+PC9zdmc+');
        background-position: center;
        background-size: contain;
        top: 0;
        left: 0;
    }

    &:hover {
        filter: saturate(0.5);

    }

    &:active, &:focus {
        outline: 0;
        box-shadow: 0;
    }
}


.navigation-search-icon {
    position: absolute;
    content: "";
    top: 10px;
    left: 10px;
    background-image: url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNy44OCAyNy44OCI+PGc+PGc+PHBhdGggc3R5bGU9ImZpbGw6I2ZmODY4MzsiIGQ9Ik0xMi4wNSwyNC4xYTEyLjA1LDEyLjA1LDAsMSwxLDEyLjA1LTEyQTEyLjA3LDEyLjA3LDAsMCwxLDEyLjA1LDI0LjFabTAtMTkuNjlhNy42NCw3LjY0LDAsMSwwLDcuNjQsNy42NEE3LjY1LDcuNjUsMCwwLDAsMTIuMDUsNC40MVoiLz48cmVjdCBzdHlsZT0iZmlsbDojZmY4NjgzOyIgeD0iMjAuNjYiIHk9IjE3Ljk3IiB3aWR0aD0iNC40MSIgaGVpZ2h0PSI5Ljc4IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtOS40NyAyMi44Nikgcm90YXRlKC00NSkiLz48L2c+PC9nPjwvc3ZnPg==');
    @include enforce-square(20px);
    background-position: center;
    background-size: contain;

    &:hover {
        filter: saturate(0.5);

    }

    &:active, &:focus {
        outline: 0;
        box-shadow: 0;
    }
}

.radio-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    .radio-row-element {
        margin: 20px;
        flex: 1;
        label {
            font-weight: bold;
            text-transform: uppercase;
        }

        &.selected {
            color: $primary;
        }
    }
}
