@import "../../styles/simples/_variables";
@import "../../styles/simples/_functions";

.global-notifications-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    z-index: 500;
}

.notification-notice, .notification-error {
    flex: 1;
    padding: 5px 10px;
    color: white;
    display: flex;
    align-items: center;
    
    .notification-close {
        @include enforce-circle(30px);
        margin-right: 20px;
        background-size: 60%;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNjUuNDQgMTQzLjcxIj48Zz48Zz48cG9seWdvbiBzdHlsZT0iZmlsbDojZmZmOyIgcG9pbnRzPSIxNjUuNDQgMC4zOSAxMjcuMTcgMC4zOSA4Mi44OSA1MC41OCAzOC4yNyAwIDAgMCA2My43NiA3Mi4yNyA2My41OSA3Mi40NiA2My43OSA3Mi42OSAxLjQ4IDE0My4zMiAzOS4xNCAxNDMuMTcgODIuNTUgOTMuOTYgMTI2LjMgMTQzLjU1IDE2My45NiAxNDMuNzEgMTAxLjQ4IDcyLjg4IDE2NS40NCAwLjM5Ii8+PC9nPjwvZz48L3N2Zz4=');
        &:hover {
            background-color: rgba(0,0,0,.2);
        }
    }

    .notification-field {
        font-weight: bolder;
        margin-right: 10px;
    }
}

.notification-notice {
    background-color: darken($success, 10%);
}

.notification-error {
    background-color: lighten($error, 20%);
    
}
