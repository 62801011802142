@import "../../styles/simples/_variables.scss";
@import "../../styles/simples/_functions.scss";

.product-image-uploader {
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 2pt;
    position: relative;
    &:active, &:focus {
        box-shadow: unset;
        outline: 0;
    }

    &:active {
        border-color: $primary;
    }

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: rgba(255,255,255,0.3);

    button.input-clear {
        position: absolute;
        top: 5px;
        right: 5px;
        index: 10;
    }
}
