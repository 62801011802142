@import "../../styles/simples/_variables.scss";
@import "../../styles/simples/_functions.scss";

.boxed-items-wrapper {
    background-color: white;
    padding: 30px 25px 100px;

    .boxed-items-header {
        font-weight: 600;
        font-size: 66.54px;
        text-align: center;
        margin-bottom: 20px;
    }
}

.boxed-items-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .boxed-item {
        @include enforce-size(height, 437px);

        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column nowrap;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 10px;
        margin-bottom: 10px;

        @include enforce-size(width, calc(50% - 10px));
        min-width: 320px;

        &:nth-child(3n) {
            @include enforce-size(width, 100%);
        }

        .boxed-item-card {
            @include enforce-size(width, 366px);
            @include enforce-size(height, 204px);

            display: flex;
            flex: 1;

            padding: 10px;
            color: white;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-bottom: 30px;

            .boxed-item-title {
                font-size: 48px;
                font-weight: 600;
                line-height: 50px;
                letter-spacing: -2px;
                margin-bottom: 5px;
            }

            .boxed-item-subtitle {
                font-size: 22.29px;
                font-family: "Catamaran";
                text-transform: uppercase;
                font-variant: small-caps;

                margin-top: 5px;
            }
        }

        .boxed-item-action {
            font-size: 28.19px;
            font-weight: 600;

            svg {
                margin-left: 10px;
                margin-top: 7px;
                @include enforce-size(width, 25px);
            }
        }
    }

    hr {
        height: 4px;
        width: 90%;
        background-color: transparent;
        border: 0;
        background-image: linear-gradient(to right, transparent 50%, white 0%);
        background-position: bottom center;
        background-size: 30px 5px;
        background-repeat: repeat-x;
    }
}


@include breakpoint-max(md) {
    .boxed-items-wrapper {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;

        .boxed-items-header {
            padding: 20px;
            font-size: 40px;
            margin-bottom: 0;
            background-color: $background;
        }
    }

    .boxed-items-container {
        padding: 0;
        .boxed-item {
            @include enforce-size(width, 100%);

            &:first-child {
                margin-top: 0;
            }

            .boxed-item-card {
                @include enforce-size(width, 290px);

                .boxed-item-title {
                    font-size: 30px;
                    font-weight: 600;
                    line-height: 35px;
                    letter-spacing: 0;
                    margin-bottom: 7.5px;
                }

                .boxed-item-subtitle {
                    font-size: 17.89px;

                    margin-top: 7.5px;
                }
            }
        }
    }
}

.boxed-items-subheader {
  text-align: center;
}

.boxed-items-action {
  font-size: 28.19px;
  font-weight: 600;
  color: black;
  max-width: 200px;
  margin: 10px auto 20px auto;

  svg {
      margin-left: 10px;
      margin-top: 7px;
      @include enforce-size(width, 25px);
  }
}
