@import "./simples/_variables.scss";

/////// CATAMARAN

@font-face {
    font-family: "Catamaran";
    src: url("#{$cloudfront-public}/fonts/Catamaran-Regular.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/Catamaran-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Catamaran";
    src: url("#{$cloudfront-public}/fonts/Catamaran-Medium.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/Catamaran-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Catamaran";
    src: url("#{$cloudfront-public}/fonts/Catamaran-SemiBold.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/Catamaran-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Catamaran";
    src: url("#{$cloudfront-public}/fonts/Catamaran-Bold.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/Catamaran-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Catamaran";
    src: url("#{$cloudfront-public}/fonts/Catamaran-ExtraBold.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/Catamaran-ExtraBold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}



/////// ZILLA SLAB


@font-face {
    font-family: "Zilla Slab";
    src: url("#{$cloudfront-public}/fonts/ZillaSlab-Light.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/ZillaSlab-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Zilla Slab";
    src: url("#{$cloudfront-public}/fonts/ZillaSlab-LightItalic.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/ZillaSlab-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Zilla Slab";
    src: url("#{$cloudfront-public}/fonts/ZillaSlab-Regular.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/ZillaSlab-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Zilla Slab";
    src: url("#{$cloudfront-public}/fonts/ZillaSlab-Italic.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/ZillaSlab-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Zilla Slab";
    src: url("#{$cloudfront-public}/fonts/ZillaSlab-Medium.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/ZillaSlab-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Zilla Slab";
    src: url("#{$cloudfront-public}/fonts/ZillaSlab-MediumItalic.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/ZillaSlab-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Zilla Slab";
    src: url("#{$cloudfront-public}/fonts/ZillaSlab-SemiBold.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/ZillaSlab-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Zilla Slab";
    src: url("#{$cloudfront-public}/fonts/ZillaSlab-Bold.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/ZillaSlab-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}




/////// LATO


@font-face {
    font-family: "Lato";
    src: url("#{$cloudfront-public}/fonts/Lato-LightItalic.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/Lato-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Lato";
    src: url("#{$cloudfront-public}/fonts/Lato-Regular.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/Lato-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("#{$cloudfront-public}/fonts/Lato-Medium.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/Lato-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("#{$cloudfront-public}/fonts/Lato-Bold.woff2") format("woff2"),
        url("#{$cloudfront-public}/fonts/Lato-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
}
