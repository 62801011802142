@import "../../../styles/simples/_variables.scss";
@import "../../../styles/simples/_functions.scss";

$image-size: 100px;
$chat-container-width: 800px;

.chat {
    margin: 20px;
    max-width: $chat-container-width;

    @include breakpoint-max(md) {
        margin: 0;
        .section-divider {
            display: none;
        }
    }
    textarea:disabled {
        color: $placeholder;
        border-color: $placeholder;
    }
}

.chat__messages {
  max-height: 50vh;
  overflow-y: scroll;
}

.chat__header {
    margin-bottom: 10px;

    @include breakpoint-max(md) {
        display: none;
    }
    
    h1 {
        font-size: 30px;

        .bold {
            font-weight: bold;
        }
    }
}

.chat__product {
    background-color: transparentize($light-gray, 0.6);
    display: flex;
    padding: 10px 20px;
    border-bottom: 3px solid $placeholder;
    margin-bottom: 20px;

    @include breakpoint-max(md) {
        display: block;
    }

    &__seller {
        display: flex;
        flex: 1;
        align-items: center;
        margin-left: 5px;
        margin-right: 5px;

        @include breakpoint-max(sm) {
          display: flex;
          justify-content: left;
        }

        @include breakpoint-max(md) {
            margin-bottom: 20px;
        }

        &__icon {
            width: 50px;

            @include breakpoint-max(md) {
                width: 40px;
            }

            img {
                width: 100%;
            }
        }

        &__name {
            font-family: 'Catamaran';
            font-size: 18px;
            margin-left: 10px;
            font-weight: bold;

            @include breakpoint-max(md) {
                // display: none;
            }
        }
    }

    &__item {
        display: flex;
        flex: 1;

        @include breakpoint-max(md) {
            margin-left: 50px;
        }

        @include breakpoint-max(sm) {
          margin-left: 0px;
        }

        &:hover {
            cursor: pointer;
        }

        &__image {

            img {
                width: $image-size;
                height: $image-size;
                object-fit: cover;

                @include breakpoint-max(md) {
                    width: $image-size / 2;
                    height: $image-size / 2;
                }
            }
        }

        &__description {
            max-width: 200px;
            font-family: 'Catamaran';
            font-size: 16px;
            margin-left: 10px;

            &__title {
                margin-bottom: 10px;
            }

            &__price {
                font-weight: bold;

                .currency {
                    font-family: 'Arial', sans-serif;
                    font-size: 14px;
                }
                @include breakpoint-max(sm) {
                  margin-top: -10px;
                }
            }
        }
    }

    &__status {
        display: flex;
        align-items: center;

        @include breakpoint-max(sm) {
          margin-top: -8px;
        }

        @include breakpoint-max(md) {
            justify-content: center;
            margin-top: 20px;
        }

        &__value {
            font-family: 'Catamaran';
            font-size: 14px;
            font-weight: bold;
            text-transform: capitalize;
            @include breakpoint-max(md) {
                font-size: 16px;
            }

            &.available {
                color: $success;
            }

            &.unavailable {
                color: $primary;
            }
        }
    }
}

.chat-message {
    padding: 20px;
    display: flex;

    &__icon {
        margin-right: 10px;

        &__crop {
            width: 40px;
            height: 40px;
            position: relative;
            overflow: hidden;
            border-radius: 50%;

            img {
                display: inline;
                margin: 0 auto;
                height: 100%;
                width: auto;
            }
        }
    }

    &__content {
        max-width: 500px;

        &__name {

            h3 {
                font-family: 'Catamaran';
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 5px;
            }
        }

        &__message {

            p {
                font-family: 'Catamaran';
                font-size: 14px;
                margin-bottom: 10px;
            }
        }

        &__timestamp {
            font-family: 'Catamaran';
            font-size: 12px;
            color: $placeholder;
        }
    }
}

.send-message-textarea {
  width: 10% !important;
  border: none !important;
  padding-top: 20px !important;
  padding-right: 80px !important;
}

.send-message-button {
  position: absolute;
  right: 0;
  margin: 15px 10px 0px 0px;
  z-index: 10;
}

.mobile-chat-avatar {
    max-width: 20px;
    min-width: 20px;
    max-height: 20px;
    min-height: 20px;
}

.chat__product__item__container {
  @include breakpoint-max(sm) {
    margin-top: -15px;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
  }
}

.send-message-field {
  width: 100%;
}