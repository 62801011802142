@import "simples.scss";
@import "./global/forms.scss";
@import "./global/spinner.scss";
@import "./global/fonts.scss";

body {
    font-family: "Zilla Slab", "Arial";
    color: $text;
    background-color: $background;
}

.section-title {
    font-family: "Zilla Slab";
    font-size: 38.64px;
}

.primary-gradient {
    background: #ff8683; /* Old browsers */
    background: -moz-linear-gradient(left,  #ff8683 0%, #ff8683 24%, #f49e83 86%, #f49e83 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #ff8683 0%,#ff8683 24%,#f49e83 86%,#f49e83 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #ff8683 0%,#ff8683 24%,#f49e83 86%,#f49e83 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8683', endColorstr='#f49e83',GradientType=1 ); /* IE6-9 */
}

.success-gradient {
  background: #2ac56d; /* Old browsers */
  background: -moz-linear-gradient(left,  #2ac56d 0%, #2ac56d 24%, #4ad887 86%, #4ad887 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  #2ac56d 0%,#2ac56d 24%,#4ad887 86%,#4ad887 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  #2ac56d 0%,#2ac56d 24%,#4ad887 86%,#4ad887 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2ac56d', endColorstr='#4ad887',GradientType=1 ); /* IE6-9 */
}

.btn.success-gradient {
  &:focus, &:active {
      outline: 0;
      border: 0;
      box-shadow: unset;
  }
  border: 0;
  color: white;
  font-family: "Zilla Slab";
  font-weight: 700;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  &:hover {
      background: darken(#2ac56d, 5%); /* Old browsers */
      background: -moz-linear-gradient(left,  darken(#2ac56d, 5%) 0%, darken(#2ac56d, 5%) 24%, darken(#4ad887, 5%) 86%, darken(#4ad887, 5%) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left,  darken(#2ac56d, 5%) 0%, darken(#2ac56d, 5%) 24%, darken(#4ad887, 5%) 86%, darken(#4ad887, 5%) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,  darken(#2ac56d, 5%) 0%, darken(#2ac56d, 5%) 24%, darken(#4ad887, 5%) 86%, darken(#4ad887, 5%) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2ac56d', endColorstr='#4ad887',GradientType=1 ); /* IE6-9 */
  }

  &:disabled {
      filter: saturate(0%);
      &:hover {
          background: #2ac56d; /* Old browsers */
          background: -moz-linear-gradient(left,  #2ac56d 0%, #2ac56d 24%, #4ad887 86%, #4ad887 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left,  #2ac56d 0%,#2ac56d 24%,#4ad887 86%,#4ad887 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right,  #2ac56d 0%,#2ac56d 24%,#4ad887 86%,#4ad887 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2ac56d', endColorstr='#4ad887',GradientType=1 ); /* IE6-9 */
          cursor: not-allowed;
      }
  }

  &.primary-action {
    max-width: 150px;
    border: 1px dashed white;
  }
}

.btn.primary-gradient {
    &:focus, &:active {
        outline: 0;
        border: 0;
        box-shadow: unset;
    }
    border: 0;
    color: white;
    font-family: "Zilla Slab";
    font-weight: 700;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    &:hover {
        background: darken(#ff8683, 5%); /* Old browsers */
        background: -moz-linear-gradient(left,  darken(#ff8683, 5%) 0%, darken(#ff8683, 5%) 24%, darken(#f49e83, 5%) 86%, darken(#f49e83, 5%) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  darken(#ff8683, 5%) 0%, darken(#ff8683, 5%) 24%, darken(#f49e83, 5%) 86%, darken(#f49e83, 5%) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  darken(#ff8683, 5%) 0%, darken(#ff8683, 5%) 24%, darken(#f49e83, 5%) 86%, darken(#f49e83, 5%) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8683', endColorstr='#f49e83',GradientType=1 ); /* IE6-9 */
    }

    &:disabled {
        filter: saturate(0%);
        &:hover {
            background: #ff8683; /* Old browsers */
            background: -moz-linear-gradient(left,  #ff8683 0%, #ff8683 24%, #f49e83 86%, #f49e83 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  #ff8683 0%,#ff8683 24%,#f49e83 86%,#f49e83 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  #ff8683 0%,#ff8683 24%,#f49e83 86%,#f49e83 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8683', endColorstr='#f49e83',GradientType=1 ); /* IE6-9 */
            cursor: not-allowed;
        }
    }

    &.primary-action {
      max-width: 150px;
      border: 1px dashed white;
    }
}


.arrow-primary {
    fill: none;
    stroke: $primary;
    stroke-mierlimit: 10;
    stroke-width: 2px;
}

.no-vertical-overflow {
    overflow-y: hidden;
}

span.section-divider {
  display: block;
}

.section-divider {
    position: relative;

    &:before {
        position: absolute;
        content: "";
        margin-left: 5%;
        left: 0;
        width: 90%;
        height: 10px;
        background-image: url(https://d2auvn3ek7a6d4.cloudfront.net/main/svg-zigzag-break.svg);
        background-repeat: repeat-x;
        background-position: center;
    }

    &.full-divider {
        &:before {
            margin-left: 0;
            left: 0;
            width: 100%;
        }
    }

    &.secondary-divider {
      &:before {
          // background-image: url("/svg-rounded-dash.svg");
      }
    }
}

ul {
    list-style-image: url("/sml-icon.svg");

    li {
        margin-bottom: 10px;
    }
}


.btn-svg-primary {
    cursor: pointer;
    &:active {
        border: 0;
        outline: 0;
    }

    &:focus {
        border: 0;
        outline: 0;
    }

    &:hover {
        svg {
            fill: $primary;
        }
    }
}

.svg-messages-button {
    cursor: pointer;

    svg {
        path {
            fill: none;
            stroke: #33334f;
            strokeMiterlimit: 10;
            strokeWidth: 1.5px;
        }
        line {
            fill: none;
            stroke: #33334f;
            strokeMiterlimit: 10;
            strokeWidth: 1.5px;
            strokeLinecap: round;
        }
    }

    &:active {
        border: 0;
        outline: 0;
    }

    &:focus {
        border: 0;
        outline: 0;
    }

    &:hover {
        svg {
            path {
                stroke: $primary;
            }
            line {
                stroke: $primary;
            }
        }
    }
}


.double-arrow {
    margin-left: 5px;
    @include enforce-size(width, 15px);
}
