.navigation-dropdown__user-routes {
    display: flex !important;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;

    .active-link {
        color: #f0f0f0;
        text-decoration: underline;
    }
}

.navigation-dropdown__signout-button {
    padding: 5px 10px;
    font-family: inherit;
    font-size: 100%;
}
