@import "../../styles/simples/_variables.scss";
@import "../../styles/simples/_functions.scss";
@import "./event_card_mixin.scss";

@mixin card-dimensions {
    @include enforce-size(width, 346px);
    @include enforce-size(height, 556px);
}

.helper-card-dimensions {
    @include card_dimensions;
}

.event-card {
    @include card_dimensions;

    font-family: "Catamaran";
    font-weight: bold;
    flex: 1;

    background-color: white;
    margin: 20px;

    .event-card-image {
        @include enforce-size(height, 268px);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .event-card-info {
        @include enforce-size(height, 172.42px);
        font-family: "Zilla Slab", "Arial", "sans-serif";
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        padding: 10px 20px;
        font-weight: normal;
        font-style: italic;
        font-size: 24.64px;
        .event-card-info-title {
            flex: 1;
            font-weight: bold;
            font-style: normal;
        }

        .event-card-info-time {
            flex: 1;
            font-size: 20px;
        }
        .event-card-info-address {
            flex: 2;
        }

    }

    .event-card-go-to {
        padding: 20px 20px 10px;
        color: $primary;
        position: relative;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
        }
        svg {
            margin-left: 10px;
            @include enforce-size(width, 20px);
        }
        &:before {
            position: absolute;
            margin-left: 5%;
            content: "";
            top: 0;
            left: 0;
            width: 90%;
            height: 10px;
            background-image: url("#{$cloudfront-public}/main/svg-zigzag-break.svg");
            background-repeat: repeat-x;
            background-position: center;
        }
    }
}


@include breakpoint-max(sm) {
    @include mobile_card(true);
}
