@import "../../styles/simples/_variables.scss";
@import "../../styles/simples/_functions.scss";


.events-landing {
    margin-bottom: 50px;
}

.events-list {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
}

.event-cover {
    width: 100%;
    @include enforce-size(height, 390px);
    background-size: cover;
    background-position: center;
    
}


.banner-events-small {
    @include enforce-size(height, 180px);
    position: relative;
    font-size: 72px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

    .element-fill {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: inherit;
        background-color: transparentize(red, 0.6);
        z-index: 0;
    }

    p {
        z-index: 1;
        font-style: normal;
    }
}

.time-interval-row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    .listing__input__form {
        flex: 1.5;
        margin: 0 5px;
    }
    label {
        flex: 1;
        font-size: 30px;
        font-style: normal;
        margin: 0 5px;
    }
}
