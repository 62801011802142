/* based on http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, big, cite, code,
em, img, q, s,
small, strike, strong, sub, sup,
b, u, i, ol, ul, li,
fieldset, form, label, legend, canvas,  
footer, header, menu, nav {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

*, *::before, *::after {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

button{
    background-color: transparent;
    border-width: 0;
    padding: 0;
    cursor: pointer;
}

input::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0;
}

ul, ol, dd{
    margin: 0;
    padding: 0;
    list-style: none;
}

h1, h2, h3, h4, h5, h6{
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
}

html {
    font-size: 1px;
}

body {
    line-height: 1;
    font-size: 16rem;
    font-weight: 400;
    line-height: 1.3;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
