@import "../../../styles/simples/_variables.scss";
@import "../../../styles/simples/_functions.scss";

.profile-view-card {

    margin: 10px;
    border: 1px dashed transparent;
    border-radius: 4pt;
    padding: 10px;
    
    .profile-view-card-label {

        font-size: 22px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    
    .profile-view-card-inner {
        flex: 1;
        @include enforce-size(width, 280px);
        @include enforce-size(height, 200px);

        background-color: white;
        padding: 20px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;

        .profile-view-card-title {
            color: $primary;
            font-size: 36px;
            font-weight: 500;
            width: 100%;
            @include enforce-size(height, 36px);
            text-align: center;
            text-transform: capitalize;
        }

        .profile-view-card-subtitle {
            color: $primary;
            font-size: 22px;
            font-weight: lighter;
            width: 100%;
            @include enforce-size(height, 22px);
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 4px;
            margin: 10px auto;
        }

        button {
            margin-top: 20px;
        }
    }

    @include breakpoint-max(sm) {
        border-color: $gray;

        .profile-view-card-label {
            text-align: center;
        }
    }
}
