$screen-breakpoints: (
    "sm": 0px,
    "md": 768px,
    "lg": 992px
);

$sizes: (
    "small": 12,
    "normal": 18,
    "large": 24,
    "xlarge": 36
);

$sizes-map: ();
@each $size, $sizepx in $sizes {
    $sizes-map: map-merge($sizes-map, ($size: (
    "font-size": #{$sizepx}px,
    "line-height": #{$sizepx}px,
    "btn-height": #{$sizepx * 1.6}px,
)
)
)
}

$sides: ("left": "l", "top": "t", "right": "r", "bottom": "b");

$color-map: (
    "white": white,
    "black": black,
    "text": #33334f,
    "gray": #adadbc,
    "primary": #f07765,
    "secondary": rgb(235, 185, 105),
    "tertiary": #964d2d,
    "warning": #E07000,
    "error": #ff2100,
    "info": #00A6E0,
    "success": #4ad887,
    "mute": #939393,
    "alt-bg": #f2f5f9,
    "background": #f2f4f7,
    "clear": transparent,
    "light-gray": #d0d8d8,
    "yellow": #fccf3d,
    "placeholder": #cecee7
);

$white: map-get($color-map, "white");
$black: map-get($color-map, "black");
$text: map-get($color-map, "text");
$gray: map-get($color-map, "gray");
$primary: map-get($color-map, "primary");
$secondary: map-get($color-map, "secondary");
$tertiary: map-get($color-map, "tertiary");
$warning: map-get($color-map, "warning");
$error: map-get($color-map, "error");
$info: map-get($color-map, "info");
$success: map-get($color-map, "success");
$mute: map-get($color-map, "mute");
$alt-bg: map-get($color-map, "alt-bg");
$clear: map-get($color-map, "clear");
$light-gray: map-get($color-map, "light-gray");
$yellow: map-get($color-map, "yellow");
$background: map-get($color-map, "background");
$placeholder: map-get($color-map, "placeholder");


$nav-height: 40px;
$home-navbar-height: calc(#{$nav-height} * 2 + 10px);
$home-navbar-height-sm: 70px;

$alignments: left center right;


$prefix: "";


$cloudfront-public: "https://d2auvn3ek7a6d4.cloudfront.net";
