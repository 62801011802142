@import "../../styles/simples/_variables.scss";
@import "../../styles/simples/_functions.scss";

.event-attendees-list {
    margin: 30px auto;
    display: flex;
    flex-flow: row wrap;
    padding: 20px;
}

.event-attendee {
    margin: 5px;
    flex: 1;
    width: 420px;
    max-width: 420px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    background-color: white;
    padding: 10px;
    @include enforce-size(height, 75px);

    .attendee-name {
        flex: 1;
        font-weight: 500;
        font-size: 26px;
        font-style: italic;
    }

    .profile-avatar {
        flex: 1;
        @include enforce-circle(50px);
    }
}
