@import "../../styles/simples/_variables.scss";
@import "../../styles/simples/_functions.scss";

@mixin mobile_card($helpers) {
    $mobile-width: 238px;
    $mobile-height: 383px;
    $mobile-image-height: 185px;     
    $margin-bottom: 12px;              

    @if $helpers {
        .helper-card-dimensions {
            @include enforce-size(width, $mobile-width);
            @include enforce-size(height, $mobile-height);
        }
    }
    
    .event-card, .small-card {
        @include enforce-size(width, $mobile-width);
        @include enforce-size(height, $mobile-height);
        @include enforce-size(margin-bottom, $margin-bottom);


        .event-card-image {
            @include enforce-size(height, $mobile-image-height);
            img {
                @include enforce-size(height, $mobile-image-height);
            }
        }

        .event-card-info {
            @include enforce-size(height, 140px);
            padding: 5px 10px;

            .event-card-info-time {
                font-size: 14px;
                flex: 1;
            }

            .event-card-info-title {
                font-size: 18px;
            }

            .event-card-info-address {
                flex: 2;
                font-size: 16px;
            }
        }

        .event-card-go-to {
            padding: 15px 10px 5px;
            font-size: 14px;
        }
    }    
}
