@import "../../../styles/simples/_variables";
@import "../../../styles/simples/_functions";

.admin-user-table {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    padding: 0 0 20px 0;
}

.admin-user-row {
    display: flex;
    flex-flow: row nowrap;

    &.header-row {
        background-color: rgba(0,0,0,.6);
        color: $white;

        &:hover {
            cursor: default;
            background-color: rgba(0,0,0,.6);
        }
    }

    .column {
        flex: 1;
        display: flex;
        align-items: center;
        padding: 10px 5px;
    }

    &:hover {
        cursor: pointer;
        background-color: rgba(0,0,0,.2);
    }
}
