@import "../../../styles/simples/_variables.scss";
@import "../../../styles/simples/_functions.scss";

.navigation-session-messages {
    position: relative;
}

.navigation-session-messages-dropdown {
    position: absolute;
    top: 100%;
    rigth: 0;
    @include enforce-size(width, 320px);
    padding: 10px;
    border: 1px solid $light-gray;
    background-color: $white;

    a.message-row {
        margin: 5px auto;
        min-height: 100px;
        height: 100px;
    }
}

.navigation-session-messages {
    .navigation-session-messages-dropdown {
        .message-row {
            font-size: 12px;
            padding: 0;
            text-align: center;
            .message-row-first-half {

                .message-product-image-container {
                    padding: 0;
                    .message-product-image {
                        width: 100%;
                        background-position: center;
                    }
                    .message-product-status {
                        margin-top: 0;
                        @include enforce-size(height, 25px);
                    }
                }

                .message-seller-image {
                    display: none;
                }

                .message-seller-name {
                    padding: 5px;
                }
            }

            .message-row-last-half {
                flex: 1;
                padding: 0;
                align-items: center;
                .message-reply-date {
                    font-size: 9px;
                    color: $black;
                }

                .message-reply {
                    margin-top: 5px;
                    font-size: 12px;
                }

                button {
                    transition: transform 0.2s;
                }
            }
        }
    }
}
