@import "../simples/_variables.scss";

.spinner {
    z-index: 100;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex: 1;
    
    &.spinner-full {
        padding: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: transparentize(white, 0.1);
        .spinner-inner {
            width: 200px;
            height: 200px;
        }
    }

    .spinner-inner {
        border: 5px solid $primary;
        border-top-color: transparentize($primary, 0.8);
        border-right-color: transparentize($primary, 0.8);
        border-bottom-color: transparentize($primary, 0.8);

        background-color: white;
        
        transform: translateZ(0);

        animation-name: forward;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        
        border-radius: 50%;
        width: 30px;
        height: 30px;
    }
}


@keyframes forward {
    
    0% {
        transform: rotate(0deg);
    }
    
    100% {
        transform: rotate(360deg);
    }
    
}
