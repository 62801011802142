@import "../../styles/simples/_functions.scss";

.contact-popup {
    width: 100%;
}

.seller-details-container {
    margin: 20px auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    .seller-details-wrapper {
        padding: 4px;
        background-color: white;
        display: flex;
        align-items: center;
        border-radius: 500px;
        padding: 4px 20px;
        p {
            font-size: 26px;
            margin-left: 5px;
        }
    }
}

.product-details-container {
    display: flex;
    justify-content: center;
    margin: 50px auto;

    .product-details-image {
        flex: 1;
        @include enforce-square(100px);
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        img {
            max-width: 100px;
            max-height: 100px;
        }

    }

    .product-details-description {
        flex: 1;
        max-width: 300px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        padding-left: 20px;
        .title {
            font-size: 24px;
        }
        .price {
            font-size: 20px;
            font-weight: bolder;
        }
    }
}

.product-contact-actions {
    &> * {
        margin: 10px auto;
    }
}


.send-message-field {
    width: 90%;
    position: relative;
    .profile-image-element {
        position: absolute;
        top: 10px;
        left: 10px;
        @include enforce-circle(40px);
    }

    textarea {
        @include base_input_style();
        @include enforce-size(width, 100%);
        min-height: 200px;
        padding-left: 60px;
    }
}

.contact-textarea {
  margin-bottom: 10px;
}
