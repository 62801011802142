@import "../../../styles/simples/_variables.scss";
@import "../../../styles/simples/_functions.scss";

$item-feed-size-mobile: 383px;

.item-feed {
    position: relative;
    padding: 40px;

    &.no-items {
        display: none;
    }

    .item-feed-title {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .item-feed-icon {
            @include enforce-size(width, 50px);
            max-height: 50px;
        }

        .item-feed-title-element {
            font-family: "Zilla Slab";
            font-size: 38.64px;
            margin-left: 15px;

        }
    }

    .item-feed-title-secondary {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;

      .item-feed-title-secondary-element {
          font-family: "Zilla Slab";
          font-size: 30px;
          font-weight: bold;
      }
    }

    .item-feed-collection {
        margin-top: 25px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        min-height: $item-feed-size-mobile;
        @include breakpoint-max(sm) {
            overflow: auto;
            overflow-y: hidden;
            scrollbar-width: none; /* Firefox */
            -ms-overflow-style: none;  /* IE 10+ */
            &::-webkit-scrollbar { /* WebKit */
                width: 0;
                height: 0;
            }
            -webkit-overflow-scrolling: touch;
            scroll-snap-type: x mandatory;
            scroll-behavior: smooth;
        }
    }
}


.feed-left-arrow, .feed-right-arrow {
    $height: 128px;

    position: absolute;

    height: $height;
    width: 38px;

    background-color: $primary;

    top: calc(50% - #{$height / 2});

    svg {
        fill: none;
        stroke: #fff;
        stroke-miterlimit: 10;
        stroke-width: 3px;
        width: 10px;
        height: 20px;
    }

    &:hover {
        background-color: darken($primary, 10%);
    }

    @include breakpoint-max(sm) {
        width: 23px;
        svg {

        }
    }
}

.feed-left-arrow {
    left: 0px;
    transform: scaleX(-1);
}

.feed-right-arrow {
    right: 0px;
}

@include breakpoint-max(sm) {
    .item-feed {
        padding: 25px;

        .item-feed-collection {
            min-height: $item-feed-size-mobile;
        }

        .item-feed-title {

            .item-feed-title-element {
                font-size: 30px;
            }
        }
    }
}
