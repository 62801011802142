@import "../../../styles/simples/_variables.scss";
@import "../../../styles/simples/_functions.scss";

.chat-message-row {
    display: flex;
    flex-flow: row nowrap;
    margin: 20px auto;
    border-bottom: 2px solid $light-gray;
    padding-bottom: 10px;
    
    .chat-message-row-avatar {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @include enforce-size(width, 100px);
    }

    .chat-message-row-content {
        flex: 1;

        .chat-message-row-name {
            font-weight: bold;
            font-size: 18px;
        }

        .chat-message-row-reply-date {
            margin-top: 10px;
            color: $gray;
            font-size: 12px;
            font-weight: 200;
        }
    }
}
