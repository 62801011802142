@import "../../../styles/simples/_variables";
@import "../../../styles/simples/_functions";

$description-margin: 10px;
$main-photo: 250px;

.listing-container {
  margin: 40px auto;
  padding: 20px 0px;
  max-width: 1280px;
  flex-wrap: wrap;

  h1 {
    font-family: "Zilla Slab";
    font-size: 38.64px;
    font-weight: 600;
  }

  h3.listing__section__header {
    font-family: "Zilla Slab";
    font-size: 20px;
    font-weight: 600;
    margin-top: $description-margin + 25px;
    margin-bottom: $description-margin;
  }

  p {
    font-style: italic;
    margin: $description-margin 0px;
  }

  li {
    font-style: italic;
  }
}

.listing {
  &__action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    @include breakpoint-max(sm) {
        flex-flow: column nowrap;
        justify-content: center;
        .listing-header {
            align-self: center;
            margin-bottom: 20px;
        }
    }
    
    &__button {
        
        &__svg {
            @include enforce-size(width, 150px);
            @include enforce-size(height, 30px);
            border-radius: 4pt;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("/not_ready_publish.svg");
            cursor: not-allowed;
            &.ready {
                background-image: url("/ready_publish.svg");
                &:hover {
                    cursor: pointer;
                    filter: brightness(1.2);
                }
            }
        }
    }
  }

  &__gallery {
    display: flex;
    
    &__main {
      width: $main-photo;
      height: $main-photo;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__items {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      max-width: 500px;
      align-items: center;
      align-content: space-between;
    }

    &__item {
        @include enforce-square(calc(#{$main-photo} / 2 - 5px));
        margin: 2.5px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    @include breakpoint-max(sm) {
        flex-flow: column nowrap;
        align-items: center;
        
        &__items {
            flex-flow: row wrap;
            justify-content: center;
            margin-top: 20px;
        }
    }
  }

  &__input {

      &__form {

          &.price-field {
            position: relative;
            &::before {
                position: absolute;
                left: 5px;
                content: "$";
                width: 20px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 25px;
            }
            input {
                padding-left: 30px;
            }
        }

      input, textarea {
        font-weight: 400;
        background-color: rgba(255,255,255,0);
        border-color: rgba(255,255,255,0);
        font-family: "Zilla Slab";
        font-size: 16px;
        font-style: italic;

        &.large-text {
          font-size: 25px;
        }

        &::placeholder {
          font-style: italic;
          font-weight: 400;
        }

        &:focus {
          outline-color: rgba(255,255,255,0);
        }
      }

      textarea {
        padding: 10px;
      }
    }

    &__area {
      border: 1px solid #d6d9e5;
      padding: 10px;
      margin-bottom: 20px;

      &__header {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        border-bottom: 1px solid #d6d9e5;
        padding-bottom: 5px;
        margin-bottom: 10px;

        h3 {
          color: black;
          text-transform: uppercase;
          font-family: "Catamaran", sans-serif;
          letter-spacing: 0.08em;
          font-size: 14px;
        }

        p {
          margin: 0;
          margin-left: auto;
          color: #aaaabc;

          .bold {
            font-weight: 700;
          }
        }
      }
    }

    &__multiple {
      display: flex;
      flex-wrap: wrap;

      > div:first-child {
        flex: 2;
      }

      > div:nth-child(2) {
        margin-left: 20px;
      }
    }

  }

  &__options {
    background-color: #ededf4;
    padding: 15px;
    position: relative;

    .btn {
      max-width: 200px;
      margin: 0 auto;

      &:before {
        content: "";
        border: 2px solid #b6b6dd;
        position: absolute;
        width: 33%;
        right: 63%;

        @media (max-width: 768px) {
          display: none;
        }
      }

      &:after {
        content: "";
        border: 2px solid #b6b6dd;
        position: absolute;
        width: 33%;
        left: 63%;

        @include breakpoint-max(sm) {
          display: none;
        }
      }
    }
  }
}

.listing-header {
    flex: 1;
    align-self: flex-start;
}

.listing-btn-save-draft {
    flex: 1;
    @include enforce-size(width, 150px);


    border: 2px solid $primary;
    border-radius: 4pt;
    color: $primary;
    height: 30px;
    font-size: 22px;

    &:hover {
        color: darken($primary, 10%);
        border-color: darken($primary, 10%);
        background-color: $white;
    }

    &:focus, &:active {
        box-shadow: unset;
        outline: 0;
    }
}

.listing-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    .listing-popup-content {
        width: 320px;
        max-width: 90%;
        display: flex;
        flex-flow: column nowrap;
        padding: 20px;
        
        background-color: $white;
        border: 2px solid transparentize($primary, 0.2);
        border-radius: 4pt;
        text-align: center;
    }
}

.create-listing {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;

  &__title {
    font-size: 180%;
    font-weight: bold;
    margin: 20px auto 0px auto;
  }

  &__subtitle {
    font-weight: bold;
    margin: 0 auto;
    padding: 16px 0px;
    display: flex;
  }

  &__tips {
    display: flex;
    flex-flow: row;
    text-align: center;
  }

  &__tips__header {
    font-size: 140%;
    font-weight: bold;
    margin: 20px 0px 8px 0px;
  }

  &__tips__subheader {
    font-size: 120%;
    font-weight: bold;
  }

  &__nav-button {
    margin: 20px 0px 30px 0px;
    height: 40px;
    width: 120px;
    font-size: 120%;
  }

  &__tips__text {
    padding: 0px 30px;
  }

  &__header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  &__footer {
    margin-top: 12px;
    background-color: $white;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  &__company-name {
    font-size: 110%;
    font-weight: bold;
    padding: 0px 10px;
    background-color: $white;
    margin-left: -4px;
  }

  &__price-input {
    width: 80%;
  }

  &__step-number {
    color: $primary;
    background-color: $white;
    border-radius: 50%;
    @include enforce-circle(20px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }

  &__avatar {
    max-width: 60px;
    min-width: 60px;
    max-height: 60px;
    min-height: 60px;
    border-radius: 50%;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__input-with-placeholder::placeholder {
    color: $placeholder;
    font-style: italic;
    font-family: "Zilla Slab";
  }

  &__input-with-placeholder {
    font-size: 165%;
    font-style: italic;
    font-family: "Zilla Slab";
  }
}

.profile__list-item-plus-sign {
  border: 2px solid $white;
  margin-right: 8px;
  height: 80%;
  width: 20px;
  text-align: center;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-image-element {
  max-width: 50px;
  min-width: 50px;
  max-height: 50px;
  min-height: 50px;
  border-radius: 50%;
}
