$card-width: 300px;
$card-height: 350px;
$card-margin: 10px;

.container {
  max-width: 1280px;
  margin: 10px auto;
  padding: 20px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;

  h1 {
    font-family: "Zilla Slab", "Arial";
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 10px;
  }
}

.blog-card {
  height: $card-height;
  width: $card-width;
  margin: $card-margin;
  background-color: #ffffff;

  .blog-card-image {
    width: 100%;
    height: 200px;
    background-size: cover;
    object-fit: contain;
  }

  .blog-card-content {
    padding: 20px;
    text-align: center;
    position: relative;
  }

  .blog-card-title {
    font-family: "Zilla Slab", "Arial";
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    height: 60px;
  }

  .blog-card-footer {
    margin-top: 'auto';
    position: relative;

    ::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 10px;
      background-image: url(https://d2auvn3ek7a6d4.cloudfront.net/main/svg-zigzag-break.svg);
      background-repeat: repeat-x;
      background-position: center;
    }
  }

  .blog-card-action {
    color: #f46969;
    font-family: 'Catamaran';
    font-weight: 600;
    display: inline-block;
    margin-top: 20px;

    svg {
      width: 15px;
    }
  }

  .blog-card-date {
    background-color: #ffffff;
    padding: 5px 10px;
    font-family: 'Catamaran';
    font-weight: 800;
    letter-spacing: 0.1em;
    display: inline-block;
    position: absolute;
    transform: translate(-62px, -50px);
    text-transform: uppercase;
  }
}
