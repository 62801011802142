@import "../../styles/simples/_variables.scss";
@import "../../styles/simples/_functions.scss";

.avatar-uploader {
    @include enforce-square(225px);
    @include base_input_style();
    
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    margin: auto;

    &.cover-image {
        @include enforce-size(width, 470px);
        @include primary_background();
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        svg {
            line, circle {
                stroke: $white;
            }
        }
    }

    svg {
        @include enforce-square(77.34px);
        
        line, circle {
            fill: none;
            stroke: $primary;
            stroke-miterlimit: 10;
            stroke-width: 3px;
        }
    }

    &:hover {
        border-color: $primary;
    }

    &.drag-active {
        border-width: 4px;
        border-style: dashed;
    }

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;


    @include breakpoint-max(sm) {
        &.cover-image {
            margin: 20px auto;
            @include enforce-size(width, 100%);
            max-width: 300px;
            @include enforce-size(height, 150px);
        }
    }
}
