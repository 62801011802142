@import "./_variables.scss";

@mixin enforce-wh($width, $height) {
    @include enforce-size(width, $width);
    @include enforce-size(height, $height);
}

@mixin enforce-size($what, $size) {
    #{$what}: $size;
    min-#{$what}: $size;
    max-#{$what}: $size;
}

@mixin enforce-square($size) {
    @include enforce-size(width, $size);
    @include enforce-size(height, $size);
}

@mixin enforce-circle($size) {
    @include enforce-square($size);

    border-radius: 50%;
}


@function next-breakpoint($bp, $breakpoints: $screen-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $bp);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@mixin breakpoint-min-max($bp-min, $bp-max: false) {
    $min: map-get($screen-breakpoints, $bp-min);
    $max: null;
    
    @if $bp-max and $bp-max != $bp-min {
        $max: map-get($screen-breakpoints, $bp-max) !global;
    } @else {
        $max: map-get($screen-breakpoints, next-breakpoint($bp-min)) !global;
    }

    @media screen and (min-width: $min) and (max-width: $max - .01) {
        @content
    }
}

@mixin breakpoint-max($bp) {
    $bp-max: map-get($screen-breakpoints, next-breakpoint($bp));
    @media screen and (max-width: $bp-max) {
        @content
    }
}

@mixin breakpoint-min($bp) {
    $bp-min: map-get($screen-breakpoints, next-breakpoint($bp));
    @media screen and (min-width: $bp-min) {
        @content
    }
}



/////////////// These are specific to ours just to simplify forms across different types, functions that apply general styles, this allows to `@import "...._functions.scss"` then `@include ....` whatever function in the selector and still override completely when needed.

@mixin base_input_style() {
    padding: 10px;
    border-radius: 3pt;
    border: 1px solid darken($background, 5%);
    font-family: "Zilla Slab";
    font-size: 16px;
    
    &::placeholder {
        color: $placeholder;
        font-weight: 500;
    }
    &:focus {
        outline-color: darken($background, 10%);
    }
}


@mixin primary_background() {
    background: #ff8683; /* Old browsers */
    background: -moz-linear-gradient(left,  #ff8683 0%, #ff8683 24%, #f49e83 86%, #f49e83 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #ff8683 0%,#ff8683 24%,#f49e83 86%,#f49e83 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #ff8683 0%,#ff8683 24%,#f49e83 86%,#f49e83 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8683', endColorstr='#f49e83',GradientType=1 ); /* IE6-9 */
}
