@import "../../../styles/simples/_variables.scss";
@import "../../../styles/simples/_functions.scss";
@import "./_nav_variables.scss";


.navigation {
    @include enforce-size(height, $nav-variables-height-normal);
    width: 100%;

    background-color: white;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0 10px;
    z-index: 100;

    .navigation-logo {
        flex: 1;
        @include enforce-size(width, 200px);
        min-width: 150px;
        a {
            height: 100%;
            min-width: 100%;
        }

        @include breakpoint-max(sm) {
            max-width: 50%;
        }
    }

    .active-link {
        color: $primary;
    }

    .navigation-search {
        flex: 1;
        min-width: 200px;
        position: relative;

        .navigation-search-input {
            position: relative;
            @include enforce-size(width, inherit);
            @include enforce-size(height, inherit);

            .input-clear {
                position: absolute;
                right: 10px;
                top: 6.5px;
            }

            .navigation-search-results {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                padding: 0 50px;
                background-color: $background;
                border: 1px solid $primary;
                height: auto;
                max-height: calc(100vh - 150px);
                overflow-y: auto;

                .navigation-search-result {
                    padding: 10px 0;
                    display: block;
                    border-bottom: 1px solid $light-gray;

                    span {
                        font-weight: bold;
                        font-size: 12px;
                        text-transform: uppercase;
                        margin-right: 10px;
                        &.result-type-product {
                            color: $success !important;
                        }
                        &.result-type-maker {
                            color: $info !important;
                        }
                    }
                }

                @include breakpoint-max(sm) {
                    top: calc(100% + 10px);
                }
            }
        }

        .navigation-search-icon {
            top: 5px;
            left: 10px;
            z-index: 1;
            @include enforce-square(30px);
        }

        @include breakpoint-max(sm) {
            @include enforce-circle(38px);
            order: 3;
            flex: 0.5;
            max-width: 12.5%;

            .navigation-search-icon {
                top: 0;
                right: 0;
                @include enforce-square(35px);
            }

            .navigation-search-input {
                display: none;
                &.show-mobile {
                    display: block;
                }
            }

            .navigation-search-input {
                position: fixed;
                @include enforce-size(width, calc(100vw - 14px));

                top: $nav-variables-height-normal;
                left: 0;

                input {
                    margin-left: 7px;
                    border-radius: 0;
                    border-right: 0;
                    border-left: 0;
                    border-top: 5px solid $primary;
                    border-bottom: 5px solid $primary;
                    box-shadow: 0 10px 10px 5px rgba(0,0,0,.5);
                }

                .input-clear {
                    top: 10px;
                }

                @include breakpoint-max(sm) {
                    input.nav-search {
                        margin: 0;
                    }
                }
            }
        }
    }

    input.nav-search {
        width: 100%;
        background-color: $background;
        padding-left: 50px;
        padding-right: 50px;
        margin: 0;
        &:focus {
            outline-color: darken($primary, 10%);
            outline-width: 2px;
        }

        &::placeholder {
            color: $text;
            font-weight: 400;
        }
    }

    .navigation-actions {
        flex: 1;
        min-width: 445px;
        display: flex;
        flex-flow: row nowrap;

        height: inherit;
        margin-right: 0px;

        .navigation-categories {
            flex: 1;
            min-width: 175px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .navigation-events {
            flex: 1;
            @include enforce-size(width, 100px);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            .select-dropdown {
                padding: 10px;
            }

            @include breakpoint-max(md) {
                display: none;
            }
        }

        .navigation-no-session, .navigation-session {
            flex: 3;
            // min-width: 300px; //Remove this b/c it was causing styling bugs on devices for the navbar
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
        }

        .navigation-session {
            justify-content: flex-end;
            height: inherit;
            padding-right: 0;
            &> * {
                height: inherit;
            }

            .navigation-session-user, .navigation-session-messages {
                display: flex;
                align-items: center;
            }

            .navigation-session-messages {
                justify-content: flex-end;
                position: relative;

                .unread-messages-icon {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @include enforce-circle(25px);

                    background-color: $primary;
                    font-weight: bold;
                    font-size: 14px;
                    color: white;
                    top: calc(50% - 25px);
                    right: -12.5px;
                    width: auto;
                    max-width: none;
                    max-height: none;
                    padding: 2px;
                }

            }

            .navigation-session-user {
                flex: 1;
                position: relative;
                //justify-content: flex-end;
                a {
                    flex: 1;

                }

                .profile-avatar {
                    @include enforce-circle(35px);
                    flex: 1 0;
                    .profile-image-element {
                        @include enforce-circle(35px);

                        border: 1px solid $primary;
                    }
                }

                .navigation-avatar-container {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-right: 10px;
                    flex-flow: row nowrap;
                    flex: 1 0;
                    width: 75px;
                    min-width: 75px;
                    max-width: 75px;
                }

                .navigation-user-dropdown {
                    background-color: white;
                    position: absolute;
                    top: $nav-variables-height-normal;
                    rigth: 10px;

                    display: flex;
                    flex-flow: column nowrap;
                    z-index: 200;
                    width: 100%;
                    justify-content: center;
                    align-items: flex-end;
                    text-align: right;
                    padding: 20px;
                    a {
                        margin: 10px 0;
                    }
                }
            }
        }

        @include breakpoint-max(sm) {
            min-width: unset;
            width: 45px;
            max-width: 45px;
            order: 2;
            flex: 0.5;
            max-width: 12.5%;

            .navigation-categories, .navigation-no-session {
                display: none;
            }
            .navigation-session {
                justify-content: flex-start;
                min-width: auto;
                .navigation-session-user {
                    display: none;
                }
            }
        }
    }

    .navigation-mobile-dropdown {
        flex: 2;
        @include enforce-circle(38px);
        max-width: 25%;

        button {
            @include enforce-circle(38px);
            padding-top: 5px;

            svg {
                fill: none;
                stroke: #fff;
                stroke-miterlimit: 10;
                stroke-width: 3px;
                width: 10px;
                height: 20px;
                transform: rotate(90deg);
            }

            &:active, &:focus {
                outline: 0;
                box-shadow: 0;
            }

        }

        @include breakpoint-min(md) {
            display: none;
        }
    }
    @include breakpoint-max(sm) {
        justify-content: space-between;
    }
}


.navigation-mobile-dropdown-container {
    display: none;
    padding: 0 10px;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background-color: $primary;
    margin: 0;
    overflow: auto !important;
    z-index: 10;
    @include breakpoint-max(sm) {
        position: fixed;
        display: block;
    }

    .navigation-mobile-dropdown-default {
        display: flex;
        flex-flow: column nowrap;

        .navigation-mobile-dropdown-header {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            @include enforce-size(height, $nav-variables-height-normal);

            .close-dropdown {
                //position: absolute;
                //left: 20px;
                //top: 10px;
                flex: 1;
                @include enforce-circle(38px);
                filter: saturate(0) brightness(5);
                border-radius: 50%;
                padding: 5px;
                &:hover {
                    background-color: rgba(255,255,255,0.2);
                }
            }

            .navigation-mobile-dropdown-logo {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                @include enforce-size(width, 200px);
                min-width: 150px;
                margin: 0 auto;
                a {
                    height: 100%;
                    min-width: 100%;
                }
                svg {
                    g g path {
                        fill: white !important;
                    }
                }
            }
            .navigation-placeholder-flex {
                flex: 1;
                @include enforce-circle(38px);
            }
        }
    }

    hr {
        height: 4px;
        width: 90%;
        background-color: transparent;
        border: 0;
        margin-top: 0;
        background-image: linear-gradient(to left, transparent 50%, white 0%);
        background-position: bottom center;
        background-size: 30px 2px;
        background-repeat: repeat-x;
    }

    .navigation-mobile-dropdown-categories {
        color: white;
        text-align: center;
        display: block;
        h4 {
            font-size: 42px;
            font-weight: 500;
            margin: 20px auto;
        }

        a {
            font-size: 26px;
            width: 90%;
            line-height: 50px;
            text-align: center;
            display: block;
            margin: 0 auto;
            &:hover {
                color: rgb(220, 220, 220);
            }
            border-bottom: 2px solid rgba(0,0,0,.1);
        }
    }

    .navigation-mobile-section-nav {
        display: flex;
        flex-flow: row wrap;
        @include enforce-size(width, 100%);
        @include enforce-size(height, 80px);
        margin: 10px auto;

        button {
            flex: 1;
            margin: 2px;
            background-color: $white;
            border-radius: 0;
        }

        a {
            flex: 1;
            margin: 2px;
            border-radius: 0;

            @include enforce-size(height, 40px);
        }
    }
}

.listing-row-action {
  @include breakpoint-max(sm) {
    display: none !important;
  }
}

.navigation-mobile-actions {
  button {
      width: 100%;
      margin: 2px;
      background-color: $white;
      border-radius: 0;
  }
}
